const initBlock = ( $block ) => {
	/**
	 * Prepare accordion.
	 */
	$block.find( '.accordion__section:not(.is-current) .accordion__body' ).hide();

	/**
	 * Handle accordion__head click.
	 */
	$block.find( '.accordion__head' ).on( 'click', function( event ) {
		event.preventDefault();

		$( this )
			.siblings()
			.slideToggle()
			.parent()
			.toggleClass( 'is-current' )
			.siblings()
			.removeClass( 'is-current' )
			.find( '.accordion__body' )
			.slideUp();
	} );
};

app.loadBlock( initBlock, 'accordion', '.js-section-accordion' );
